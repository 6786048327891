import * as React from "react"

import Seo from "../components/seo"

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>Désolé, cette page n'existe pas.</p>
  </>
)

export default NotFoundPage
